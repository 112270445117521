import React, { useState } from "react";
import { Button, Jumbotron, Container, Row, Col } from "react-bootstrap";
import ReactPlayer from "react-player";
import styled from "styled-components";
import Image from 'react-bootstrap/Image';

import { TeamContainer } from "components";


// import volleyballImage from "assets/";
import team from "assets/team.png";
import nepalTeam from "assets/nepal-team.JPG";

import data from './nepal_team_info.json';
import css from "./nepal_team.module.css";


const StyledJumbotron = styled.div`
  height: 80vh;
  width: auto;
  background-image: url(${nepalTeam});
  margin-top: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  h1 {
    margin-top: 125px;
    font-size: 6em;
    font-family: "Merriweather", serif;
  }

  /* Mobile */
  @media (max-width: 768px) {
    width: auto;
    height: 20vh;
    background-image: url(${nepalTeam});
    margin-left: 0px;
    margin-top: 60px;
    h1 {
      margin-left: 100px;
      margin-top: 250px;
      font-size: 3em;
    }
  }`
;

function NepalTeam() {

    let nepal_team = data["nepal-team"];
    console.log(nepal_team);
  

  return (
    <div style={{marginTop: "-10px", paddingBottom: "50px", backgroundColor: "#f7f4f2"}}>
        <Row>
        <Col className="p-0">
            <StyledJumbotron fluid>
              <Container fluid>
              </Container>
            </StyledJumbotron>
        </Col>
      </Row>

        <Container style={{alignItems: 'left !important'}} className="p-md-5 p-sm-0">
                <h2 id="vision" className=" mt-2 mb-2">
                        Nepal Team
                      </h2>
                      <p
                        className={css.mainParagraph}
                        
                      >
                        Our local team in Nepal is composed of experienced individuals that are dedicated to impact the Himalayan community. This team not only helps coordinate with our Boston Team, but they help with operations within the targeted community. Our Nepal team is a vital part of our foundation and with them we are able to accomplish what we have already and more to come in the future. 
                      </p>
        </Container>

        

        {/* Nepal Team Members */}
        {nepal_team.map(user => (<TeamContainer user = {user} />))}

    </div>
  );
}

export { NepalTeam };
