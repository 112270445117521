import React from "react";
import data from './stories.json'
import { Button, Jumbotron, Container, Row, Col } from "react-bootstrap";
import ang from "assets/ang.JPG";





function Stories() {
  let stories = data["stories"]
  console.log(stories[0].id);
  

  return (
    <div style={{marginTop: "100px", marginBottom: "50px", backgroundColor: "#f7f4f2"}}>
      <a>
        <div>
          <Container fluid>

          <Container className = "stories">
            <p style={{fontSize: "38px"}}>Individual accounts</p>
            <Row style={{minWidth:"100%"}}>
              <Col className="col-md-4 col-md-offset-2">
                <div>
                    <img alt="don" style={{height: "170px"},{width: "170px"}} src={ang}/>
                </div>
                
                <div>
                    <p><b>{stories[0].title}</b></p>
                </div>
              </Col>
              <Col className="col-md-4">
                <div>
                    <img alt="don" style={{height: "170px"},{width: "170px"}} src={ang}/>
                </div>
                
                <div>
                    <p><b>{stories[1].title}</b></p>
                </div>
              </Col>
              <Col className="col-md-4">
                <div>
                    <img alt="don" style={{height: "170px"}, {width: "170px"}} src={ang}/>
                </div>
                
                <div>
                    <p><b>{stories[2].title}</b></p>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <p>{stories[0].short_description}</p>
                <p><a href="./#/about">Read More {">"}</a></p>

              </Col>
              <Col>
                <p>{stories[1].short_description}</p>
                <p><a href="./#/about">Read More {">"}</a></p>

              </Col>
              <Col>
                <p>{stories[2].short_description}</p>
                <p><a href="./#/about">Read More {">"}</a></p>

              </Col>
            </Row>
            
        </Container>
        </Container>

        </div>
      </a>
    </div>
  );
}

export { Stories };
