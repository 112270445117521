import React, { useState } from "react";

import { SocialIcon } from "react-social-icons";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import css from "./footer.module.css"


const Footer = (props) => {
  return (
    <footer
      id="contact"
      style={{
        height: "100px",
        textAlign: "left",
        padding: "35px",
        backgroundColor: "white",
        height: "fit-contents"
      }}
    >
      <Container fluid>
        <Helmet>
          <script src="https://kit.fontawesome.com/d299336aee.js" crossorigin="anonymous"></script>
        </Helmet>
        
        <Row>
            <Col>
              <h5 style={{fontFamily: "Arial", fontWeight:"bold", color:"#616161"}}>CONTACT</h5>
              <Row>
                <div style={{width:"25px"}}>
                  <Col>
                    <i className="fas fa-home" style={{width:"20px"}}></i>
                  </Col>

                </div>
                
                <Col>
                  <h6 style={{color:"darkgrey", marginTop:"3px"}}>Vision Education Foundation</h6>
                </Col>
              </Row>
              
              <h6 style={{color:"darkgrey", marginLeft:"25px"}}>667 Saratoga Street</h6>
              <h6 style={{color:"darkgrey", marginLeft:"25px"}}>East Boston, MA 02128</h6>
              <Row>
                <div style={{width:"25px"}}>
                  <Col>
                    <i className="fas fa-phone-alt"></i>
                  </Col>

                </div>
                
                <Col>
                  <h6 style={{color:"darkgrey", marginTop:"2px"}}>857-529-7374</h6>
                </Col>
              </Row>
              <Row>
                <div style={{width:"25px"}}>
                  <Col>
                    <i className="fas fa-envelope"></i>
                  </Col>

                </div>
                
                <Col>
                  <h6 style={{color:"darkgrey", marginTop:"2px"}}>support@visioneducationfoundation.org</h6>
                </Col>
              </Row>
              
            </Col>
            <Col style= {{display: "flex", flexDirection: "column", float:"left", alignItems:"left", justifyContent:"left", textAlign:"left"}}>
              <div className={css.social} style={{marginLeft: "0px"}}>
                <h5 style={{fontFamily: "Arial", fontWeight:"bolder", color:"#616161"}}>SUPPORT</h5>
                <a
                  variant="primary"
                  sz="lg"
                  target="_blank"
                  className={css.footerFont}
                  style={{marginLeft: "0px", padding: "0px", width:"110px", color:"darkgray"}}
                  href="https://donorbox.org/vision-education-foundation"
                >
                  Donate >
                </a>
                
              </div>
              <div className={css.social} style= {{float:"left", alignItems:"left", justifyContent:"left", textAlign:"left"}}>
                <a
                  variant="primary"
                  sz="lg"
                  style={{marginLeft: "0px", width:"110px", color:"darkgray"}}
                  className={css.footerFont}
                  target="blank"
                  // href="https://donorbox.org/vision-education-foundation"
                  href="https://thevisioneducationfoundation.typeform.com/to/MbfUaRXs"
                >
                  Contact Us >
                </a>
              </div>
                
              <div className={css.social} style= {{float:"left", alignItems:"left", justifyContent:"left", textAlign:"left"}}>
                <a
                    variant="primary"
                    sz="lg"
                    className={css.footerFont}
                    style={{marginLeft: "0px", width:"110px", color:"darkgray"}}
                    target="blank"
                    // href="https://donorbox.org/vision-education-foundation"
                    href="https://thevisioneducationfoundation.typeform.com/to/b0bdfhsC"
                  >
                    Join Us >
                </a>
              </div>
              
            </Col>

            <Col style= {{display: "flex", flexDirection: "column", float:"left", alignItems:"left", justifyContent:"left", textAlign:"left"}}>
              <div className={css.social} style={{marginLeft: "0px"}}>
                <h5 style={{fontFamily: "Arial", fontWeight:"bolder", color:"#616161"}}>QUICK LINKS</h5>
                <a
                  variant="primary"
                  sz="lg"
                  style={{marginLeft: "0px", padding: "0px", width:"110px"}}
                  className={css.footerFont}
                  href="#/firstproject"
                >
                  Our Work >
                </a>
                
              </div>
              <div className={css.social} style= {{float:"left", alignItems:"left", justifyContent:"left", textAlign:"left"}}>
                <a
                  variant="primary"
                  sz="lg"
                  style={{marginLeft: "0px", width:"110px"}}
                  className={css.footerFont}
                  // href="https://donorbox.org/vision-education-foundation"
                  href="#/about"
                >
                  Why VEF >
                </a>
              </div>

              <div className={css.social} style= {{float:"left", alignItems:"left", justifyContent:"left", textAlign:"left"}}>
                <a
                    variant="primary"
                    sz="lg"
                    style={{marginLeft: "0px", width:"110px"}}
                    className={css.footerFont}
                    // href="https://donorbox.org/vision-education-foundation"
                    href="#/get-involved"
                  >
                    Volunteer >
                </a>
              </div>
                
              <div className={css.social} style= {{float:"left", alignItems:"left", justifyContent:"left", textAlign:"left"}}>
                <a
                    variant="primary"
                    sz="lg"
                    style={{marginLeft: "0px", width:"110px"}}
                    className={css.footerFont}
                    target="_blank"
                    // href="https://donorbox.org/vision-education-foundation"
                    href="https://thevisioneducationfoundation.typeform.com/to/b0bdfhsC"
                  >
                    Apply >
                </a>
              </div>
              
            </Col>


            <Col>
             
              
              <div className={css.social} style={{disply:"flex" , float:"right", alignItems:"right", justifyContent:"right", alignText:"right"}}>
                  <h5 style={{fontFamily: "Arial", fontWeight:"bolder", color:"#616161"}}>SOCIAL MEDIA</h5>
                  <SocialIcon
                  bgColor="darkgrey"
                  style={{ marginLeft: "-5px" }}
                  url="https://www.instagram.com/visioneducationfoundation/"
                  />
                  <SocialIcon
                    bgColor="darkgrey"
                    style={{ marginLeft: "5px" }}
                    url="https://www.facebook.com/Visioneducationfoundation-115861536632694/"
                  />
                  
                  <Col>
                    <SocialIcon
                      bgColor="darkgrey"
                      style={{ marginLeft: "-20px" }}
                      url="https://www.youtube.com/channel/UCC652xs2nn0LwimelrW283Q/featured"
                    />
                    <SocialIcon
                      bgColor="darkgrey"
                      style={{ margin: "5px" }}
                      url="https://twitter.com/VisionEducatio3"
                    />
                  </Col>

                
              </div>
              
              
              
            </Col>
        </Row>
        <Row>
          
        </Row>
        <Container className={css.social} style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", marginTop:"0px"}}>
          
          <div style={{fontSize:"15px", color:"#616161"}}>Copyright ©2020 Vision Education Foundation Inc USA. All rights reserved.</div>

        </Container>
      </Container>
      
    </footer>
  );
};

export { Footer };
