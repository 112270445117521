import React, { useState } from "react";

import { Button, Jumbotron, Container, Row, Col } from "react-bootstrap";

import sonam from "assets/sonam.jpg";
import yojana from "assets/yojana.png";
import regan from "assets/regan.jpg";
import nora from "assets/nora.jpeg";
import manogya from "assets/manogya.png";
import max from "assets/max.png";
import wajdi from "assets/wajdi.png";
import ang from "assets/ang.JPG";
import ngawang from "assets/ngawang.JPG";
import pemba from "assets/pemba.JPG";
import tsering from "assets/tsering.jpg";
import nima from "assets/nima.JPG";
import isabel from "assets/isabel.JPG";


let images = [sonam, yojana, regan, nora, manogya, max, wajdi, isabel, ang, ngawang, pemba, tsering, nima];


function TeamContainer(props) {
    console.log(props.user);
    let person_info = props.user;
    console.log(person_info.image);
    return (
        <div style={{marginTop: "20px"}}>
            <Container>
                <div style={{display: "flex", flexDirection:"column", alignItems:"center", justifyContent:"center",marginTop:"0px"}}>
                <h1 style={{ fontFamily: "'Merriweather', serif" }}>{person_info.name}</h1>
                <p style={{color:'black'}}>{person_info.position}</p>
                <hr
                    style={{
                    textAlign: "center",
                    width: "300px",
                    color: "black",
                    marginTop:"-5px"
                    }}
                />

                </div>
                
            </Container>
            <Container style={{textAlign:"center", border:"1px solid #e7e4e2", boxShadow: "2.5px 2.5px #e7e4e2"}}>
                <Container>
                </Container>
                <Row style={{ backgroundColor: "white" }}>
                    <Col className="p-0" sm={12} md={6}>
                    <div
                        style={{
                        height: "100%",
                        backgroundImage: `url(${images[person_info.image]})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        margin: "0px",
                        }}
                    />
                    </Col>
                    <Col className="p-0" sm={12} md={6}>
                    <Container className="p-md-5 p-sm-0">
                        <h2 id="person1" className=" mt-2 mb-2">
                        {person_info.about}
                        </h2>
                        <p
                        style={{
                            fontSize: "14px",
                            lineHeight: "28px",
                            textAlign: "justify",
                            fontFamily: "Cambria, Georgia, serif"
                        }}
                        >
                            {person_info.description}
                        </p>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
        
    )
}

export { TeamContainer };
