import React, {useState} from "react";
import { Button, Jumbotron, Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

import photo1 from "assets/get-involved1.jpg";
import photo2 from "assets/get-involved2.jpg";

import css from "./involvement.module.css";

import signup from "assets/sign-up.png";
import schedule from "assets/schedule.png";
import training from "assets/training.png";
import impact from "assets/impact.png";
import blueArrow from "assets/blue-arrow.png";


const StyledJumbotron = styled.div`
  height: 70vh;
  width: auto;
  background-image: url(${photo1});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  h1 {
    margin-top: 125px;
    font-size: 6em;
    font-family: "Merriweather", serif;
  }

  /* Mobile */
  @media (max-width: 768px) {
    width: auto;
    height: 20vh;
    background-image: url(${photo1});
    margin-left: -50px;
    margin-top: 50px;
    h1 {
      margin-left: 75px;
      margin-top: 250px;
      font-size: 3em;
    }
  }`
;

function Involvement( ){

    return (
        <div style={{marginTop: "100px", paddingBottom: "50px", backgroundColor: "#f7f4f2"}}>
            <Container fluid style={{backgroundColor:"#f7f5f4 !important"}}>
              <Row>
              <Col className="p-0">
                <StyledJumbotron fluid>
                  <Container fluid>
                  </Container>
                </StyledJumbotron>
              </Col>
              </Row>
            </Container>

            <Container className="p-md-5 p-sm-0">
                <h2 id="vision" className=" mt-2 mb-2" >
                    <div style={{marginTop:"0px"}}>
                        Volunteer
                    </div>
                </h2>
                <p>
                When you volunteer through Vision Education Foundation, you become part of the success story.
                Use your time,  talent and passion to create social change and prepare our children in Nepal
                (Himalayas) to succeed in the global economy. Positive community changes require more than money
                or programs or policies. We envision a community where education and health are not a luxury and
                this kind of real and lasting change that benefits everyone is only possible when people from all
                walks of life are willing to roll up their sleeves and go where they are most needed.
                </p>
            </Container>
            <Container>
                <div class={css.centerTitle}>
                    <h2 id="vision" style={{fontFamily: "Roboto", fontWeight:"bold", fontSize:"45px", color:"#369FF4"}}>
                        <b>EASY STEP-BY-STEP</b>
                    </h2>
                    <h2 style={{fontFamily: "Roboto", fontWeight:"bold", fontSize:"45px", color:"#369FF4"}}>
                        <b>PROCESS</b>
                    </h2>

                    <Row style={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop: "50px"}}>
                        <Col>
                            <div>
                                <img alt="don" style={{height: "170px"}} src={signup}/>
                            </div>
                            
                            <div>
                                <p><b>1) Sign Up</b></p>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <img style={{height:"20px"}} src={blueArrow} />
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <img style={{height: "170px"}} src={schedule}/>
                            </div>
                            
                            
                            <div>
                                <p><b>2) Pick Schedule</b></p>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <img style={{height:"20px"}} src={blueArrow} />
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <img style={{height: "170px"}} src={training}/>
                            </div>
                            <div>
                                <p><b>3) Attend Training</b></p>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <img style={{height:"20px"}} src={blueArrow} />
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <img style={{height: "170px"}} src={impact}/>
                            </div>
                            <div>
                                <p><b>4) Impact Lives</b></p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>We value you and how you serve is important! Select the service that is suitable to get more info and complete a form that our Programs Managers will use to contact you.</p>
                        </Col>
                        <Col>
                            <p>You will have the opportunity to discuss with your VEF Program Manager your preference of how or where you would like to Volunteer and coordinate your schedule.</p>
                        </Col>
                        <Col>
                            <p>Our Program Manager will provide you with a short but effective training session in which you will be able to ask questions. We will make sure you are ready to volunteer!</p>
                        </Col>
                        <Col>
                            <p>Sharing your passion, professional, and personal experiences through the VEF program helps the students see how they can achieve their dreams!</p>
                        </Col>
                    </Row>
                    <div>
                        <a href="https://thevisioneducationfoundation.typeform.com/to/b0bdfhsC" target="_blank">
                            <b>CLICK TO APPLY {">"}</b>
                        </a>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export {Involvement};