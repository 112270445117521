import React, { useState, useEffect }  from "react";
import styled from "styled-components";
import { Button, Jumbotron, Container, Row, Col } from "react-bootstrap";
import ReactPlayer from "react-player";
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';

import "./BigQuote.css";
import frontPagePic from "assets/frontpagepic.jpg";
import frontPagePic3 from "assets/frontpagepic2.jpg";
import frontPagePic2 from "assets/frontpagepic3.jpg";
import withoutText from "assets/withouttext.jpg";
import withText from "assets/withtext.png";
import founderText from "assets/founderstory.jpg";

import css from "./main.module.css"
import newToothbrush from "assets/new-toothbrush.png";

import education from "assets/education.png";
import social from "assets/social.png";
import health from "assets/health.png";

import educationClicked from "assets/education_clicked.png";
import socialClicked from "assets/social_clicked.png";
import healthClicked from "assets/health_clicked.png";

var index = 0;
var currentPicture = frontPagePic;

const StyledJumbotron2 = styled.div`
  height: 70vh;
  width: auto;
  background-image: url(${founderText});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  h1 {
    margin-top: 125px;
    font-size: 6em;
    font-family: "Merriweather", serif;
  }

  /* Mobile */
  @media (max-width: 768px) {
    width: auto;
    height: 20vh;
    background-image: url(${founderText});
    margin-left: -50px;
    margin-top: 50px;
    h1 {
      margin-left: 75px;
      margin-top: 250px;
      font-size: 3em;
    }
  }`
;


function Main() {
  const [seconds, setSeconds] = useState(0);
  var pictures = [frontPagePic, frontPagePic, frontPagePic]

  const  hoveredEducation = () => {
    setEducationIndex(1);
  }

  const leftHoverEducation = () => {
    setEducationIndex(0);
  }

  const hoveredSocial = () => {
    setSocialIndex(1);
  }

  const leftHoverSocial = () => {
    setSocialIndex(0);
  }


  const hoveredHealth = () => {
    setHealthIndex(1);
  }

  const leftHoverHealth = () => {
    setHealthIndex(0);
  }

  const [educationIndex, setEducationIndex] = useState(0);
  const educationArray = [education, educationClicked];

  const [socialIndex, setSocialIndex] = useState(0);
  const socialArray = [social, socialClicked];

  const [healthIndex, setHealthIndex] = useState(0);
  const healthArray = [health, healthClicked];
  
  
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  function changeImage(){
    if(index == 2){
      index = 0
    }
    else {
      index += 1
    }
    currentPicture = pictures[index]
  }

  return (
    <div style={{backgroundColor: "#f7f4f2", paddingBottom: "50px"}}>
      <Container fluid>
        <Row>
          <Col className="p-0">
          {/* <StyledJumbotron fluid>
                <Container fluid>
                </Container>
          </StyledJumbotron> */}
          <Container  style={{backgroundImage: "url(" + currentPicture + ")"}} className={css.backgroundPicture} fluid></Container>
          
            {(seconds % 5 == 0 && seconds != 0) ? changeImage() : null}
          </Col>
        </Row>
        <Container className={css.centerTop} style={{minWidth:"100%"}}>
            <p className={css.whatWeDo}><b>WHAT WE DO</b></p>
            <p className={css.mainAreas}>Main Areas of Focus</p>
            <div className={css.pcView}>
              <Row style={{minWidth:"100%"}}>
                <Col className="col-md-4 col-md-offset-2">
                  <div>
                    <a href="#/about" onMouseEnter={(e) => hoveredEducation()} onMouseLeave={(e) => leftHoverEducation()}>
                      <img alt="don" className={css.iconPictures} src={educationArray[educationIndex]}/>
                    </a>
                  </div>
                  
                  <div>
                      <p><b>Education</b></p>
                  </div>
                </Col>
                <Col className="col-md-4">
                  <div>
                    <a href="#/about" onMouseEnter={(e) => hoveredSocial()} onMouseLeave={(e) => leftHoverSocial()}>
                      <img alt="don" className={css.iconPictures} src={socialArray[socialIndex]}/>
                    </a>
                  </div>
                  
                  <div>
                      <p><b>Social</b></p>
                  </div>
                </Col>
                <Col className="col-md-4">
                  <div>
                    <a href="#/about" onMouseEnter={(e) => hoveredHealth()} onMouseLeave={(e) => leftHoverHealth()}>
                      <img alt="don" className={css.iconPictures} src={healthArray[healthIndex]}/>
                    </a>
                  </div>
                  
                  <div>
                      <p><b>Health</b></p>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col className={css.columns}>
                  <p>We provide the necessary resources to teach properly and to create an ideal learning environment for the students. In addition we want to create a teaching system that will educate the youth and teach them life lessons that goes beyond standard education.</p>
                </Col>
                <Col>
                  <p>By raising awareness and implementing values in the community that will change the social standards, we will be able to change society into a place that promotes equality and inclusivity. </p>
                </Col>
                <Col>
                  <p>We envision to develop a stable and effective health system to provide reliable assistance in order to meet health standards. By providing a health system that is accessible to all, it will allow the community to receive the resources needed to maintain health standards </p>

                </Col>
              </Row>

            </div>
            <div className={css.mobileView}>
                <div>
                  <a href="#/about" onMouseEnter={(e) => hoveredEducation()} onMouseLeave={(e) => leftHoverEducation()}>
                    <img alt="don" className={css.iconPictures} src={educationArray[educationIndex]}/>
                  </a>
                </div>
                <div>
                    <p><b>Education</b></p>
                    <p>We provide the necessary resources to teach properly and to create an ideal learning environment for the students. In addition we want to create a teaching system that will educate the youth and teach them life lessons that goes beyond standard education.</p>
                </div>

                <div>
                  <a href="#/about" onMouseEnter={(e) => hoveredSocial()} onMouseLeave={(e) => leftHoverSocial()}>
                    <img alt="don" className={css.iconPictures} src={socialArray[socialIndex]}/>
                  </a>
                </div>
                
                <div>
                    <p><b>Social</b></p>
                    <p>By raising awareness and implementing values in the community that will change the social standards, we will be able to change society into a place that promotes equality and inclusivity. </p>
                </div>

                <div>
                    <a href="#/about" onMouseEnter={(e) => hoveredHealth()} onMouseLeave={(e) => leftHoverHealth()}>
                      <img alt="don" className={css.iconPictures} src={healthArray[healthIndex]}/>
                    </a>
                </div>
                
                <div>
                    <p><b>Health</b></p>
                    <p>We envision to develop a stable and effective health system to provide reliable assistance in order to meet health standards. By providing a health system that is accessible to all, it will allow the community to receive the resources needed to maintain health standards </p>
                </div>



            </div>
            
            
        </Container>

          
        <Row style={{ backgroundColor: "#f7f4f2", marginTop: "0px" }}>
          <Col>
            <div className = {css.visionContainer}>
              <div className = {css.ourVisionTxt}>
                <h2 id="vision" style={{fontFamily: "Roboto", fontWeight:"bold", fontSize:"45px", color:"#369FF4"}}>
                  Our Vision
                </h2>
              </div>
              <hr className = {css.ourVisionHR}/>
             
              <p className = {css.ourVisionParagraph}>
                Our vision is to allocate necessary educational resources that directly fulfill the needs of each individual school our team sponsors in Nepal as a way to instill greater hope and tangible success for their students.
              </p>
              
              
              <hr className = {css.ourVisionHRTwo}/>
            </div>
          </Col>
        </Row>

        <Row className={css.pcView}>
          <Col className="p-0">
            <StyledJumbotron2/>
            <div style={{textAlign: "left", alignItems: "left", marginLeft: "220px", marginTop: "-93px"}}>
              <p><a href="./#/about">Find Out More {">"}</a></p>
            </div>
            <div style={{textAlign: "right", alignItems: "right", marginRight: "230px", marginTop: "-45px"}}>
              <p><a href="./#/about">Find Out More {">"}</a></p>
            </div>
          </Col>
        </Row>

        
        {/* <Container className = {css.aboutUsContainer}> */}
        
        {/* <Row style={{ backgroundColor: "white" }}>
            <Col className="p-0" sm={12} md={6}>
              <div
                style={{
                  height: "80vh",
                  backgroundImage: `url(${newToothbrush})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  margin: "0px",
                }}
              />
            </Col>
            <Col className="p-0" sm={12} md={6}>
              <Container className="p-md-5 p-sm-0">
                <h2 className = {css.aboutusTxt} style={{color:"#369FF4"}} id="person1" className=" mt-2 mb-2">
                  [ Founding Story ]
                </h2>
                <p
                  className = {css.founderParagraph}
                >
                  
                <p>
                  Vision Education Foundation is a nonprofit organization established in November of 2019. This foundation was formed through the lens and life-story of executive director, Sonam Sherpa, who has re-written the narrative of his life by escaping poverty in Nepal through his opportunity to have a strong education. His first-hand experience, alongside the perseverance and dedication of our team, translate into the values and hopes of this foundation. Our team is highly determined and passionate about empowering children, breaking down the systemic inequalities they have been subjected to, and creating safe educational spaces for them to thrive in. 

                </p>
                <p><a href="./#/about">Find Out More {">"}</a></p>

                </p>
              </Container>
            </Col>
          </Row> */}
      {/* </Container> */}
        
      </Container>
      <Container fluid style={{marginTop: "80px"}}>
        <h1
          id="get_involved"
          className={css.joinFoundation}
          style={{ fontFamily: "'Merriweather', serif", backgroundColor:"#f7f4f2", color: "#4E6071"}}
        >
          Join Our Foundation
        </h1>
        <hr
          className = {css.joinHR}
        />
        <Row style={{ padding: "0px", backgroundColor: "#f7f5f2" }}>
          <Col className="p-3" sm={12} md={4}  style={{width:"fit-contents", borderRadius: "0px", backgroundColor:"#f7f5f2"}}>
            <div style={{backgroundColor:"#f7f5f2"}}>
            <Container
              fluid
              className={css.threeBoxes}
            >
              <a href="#/get-involved" className={css.corporateDonorDiv}>
                <Card className={css.individualVolunteerCard}>
                  <Card.Header className = {css.individualVolunteerTxt} style={{color:"white"}}>Individual Volunteer</Card.Header>
                </Card>

              </a>
              
              <p
                className=" p-sm-1 p-sm-5"
                style={{ lineHeight: "30px", fontFamily:"Georgia" }}
              >
                <p className={css.individualVolunteerText}>
                  Vision Education Foundation welcomes volunteers who are
                  dedicated to strengthening children’s education in rural
                  communities of Nepal. We accept college undergraduates,
                  graduates, and post-graduates who have the necessary teaching
                  skills to help transform the lives of these children. The
                  volunteers for this foundation will be guaranteed safe housing,
                  transportation, and food. We are excited to work with you and
                  create the change we seek to instill into the lives of these
                  children!
                </p>
                
              </p>
            </Container>
           </div>
          </Col>
          <Col className="p-3" sm={12} md={4}>
            <Container
              fluid
              className={css.threeBoxes}
            >
              <a href="https://donorbox.org/vision-education-foundation" target="_blank" className={css.corporateDonorDiv}
                // border:"1px solid #e7e4e2", boxShadow: "2.5px 2.5px #e7e4e2", paddingTop:"-50px !important" TODO
              >
                <Card className={css.corporateDonorCard}>
                  <Card.Header className={css.corporateDonorTxt} style={{color:"white"}}>
                    Corporate Donor
                  </Card.Header>
                </Card>
              </a>
              <p
                className="p-sm-1 p-sm-5"
                style={{ lineHeight: "30px", fontFamily:"Georgia" }}
              >
                <p className={css.corporateDonorText}>
                  If your corporation values serving vulnerable children through
                  life changing resources such as medical aid, clean water, food,
                  and education opportunities you can partner with our
                  organization. We are asking you to help us give these children
                  hope that there's a future for them. When you partner with
                  Vision Education Foundation, you become part of the success
                  story.
                </p>
                
              </p>
              <div className={css.centerButton}>
                <a href="https://thevisioneducationfoundation.typeform.com/to/b0bdfhsC" target="blank">
                  <Button size="lg" className={css.applyButton}>
                    APPLY
                  </Button>
                </a>
              </div>
              
            </Container>
          </Col>
          <Col className="p-3" sm={12} md={4}>
            <Container
              fluid
              className={css.threeBoxes}
            >

              <a href="#/get-involved" className = {css.educationVolunteerDiv}>
                <Card className={css.educationVolunteerCard}>
                  <Card.Header className={css.educationVolunteerTxt} style={{color:"white"}}>Educator Volunteer</Card.Header>
                </Card>
              </a>
              <p
                className="p-sm-1 p-sm-5"
                style={{ lineHeight: "30px", fontFamily:"Georgia"}}
              >
                <p className={css.educationVolunteerText}>
                  VEF is dedicated to strengthening the
                  value of an education. If you're an educator or hope to become
                  one in future. Here at VEF you can come from all across the
                  state, country, and world! You could inspire and prepare our
                  children to succeed in the global economy. Most of the teachers
                  in the school we sponsor are still in need of some teaching
                  skills. All we need from you is your excitement to empower these
                  children. Vision Education Foundation provides housing and food,
                  safety, transportation, and a classroom filled with determined
                  children to meet you!
                </p>
                
              </p>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container justify-content-center style={{marginTop: "30px"}}>
        <div>
            <p className={css.smileWithHope} style={{color: "#369FF4"}}><b>[ SMILE WITH HOPE ]</b></p>
            
        </div>
        <div className = {css.playerWrap}>
        <ReactPlayer
          className = {css.reactPlayer}
          width="100%"
          height="100%"
          controls={true}
        
          url="https://youtu.be/yBPb2XAUxWo"
        />
        </div>
      </Container>
    </div>
  );
}

export { Main };
