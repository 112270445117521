import React from "react";
import {
  HashRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
import { Main, About, Team, NepalTeam, Projects, First, Stories, BCChapter, Involvement, Work } from "containers";
import { NavBar, Footer } from "components";

function App() {
  

  return (
    <div>
      <Router baseName="/" hashType="slash">
        <NavBar />
        <Switch>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/bostonteam">
            <Team />
          </Route>
          <Route exact path="/nepalteam">
            <NepalTeam />
          </Route>
          <Route exact path="/projects">
            <Projects />
          </Route>
          <Route exact path="/home">
            <Main/>
          </Route>
          <Route exact path="/firstproject">
            <First/>
          </Route>
          <Route exact path="/stories">
            <Stories/>
          </Route>
          <Route exact path="/bcchapter">
            <BCChapter/>
          </Route>
          <Route exact path="/get-involved">
            <Involvement />
          </Route>
          <Route exact path="/work">
            <Work />
          </Route>
          <Route exact path="/">
            <Main />
          </Route>
          {/* Redirect all other routes to root */}
          <Route path="/">
            <Redirect to="/" />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
    
  );
}

export { App };
