import React, { useState } from "react";
import { Button, Jumbotron, Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

import aboutpageimg from "assets/about-page.JPG";
import educimg from "assets/educimg.JPG";
import healthimg from "assets/healthimg.JPG";
import socialimg from "assets/socialimg.JPG";
import econimg from "assets/econimg.JPG";

import education from "assets/education-sector.png";
import health from "assets/health-sector.png";
import social from "assets/social-sector.png";
import economic from "assets/economic-sector.png";

import css from "./about.module.css";


const StyledJumbotron = styled.div`
  height: 80vh;
  width: auto;
  background-image: url(${aboutpageimg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  h1 {
    margin-top: 125px;
    font-size: 6em;
    font-family: "Merriweather", serif;
  }

  /* Mobile */
  @media (max-width: 768px) {
    width: auto;
    height: 20vh;
    background-image: url(${aboutpageimg});
    margin-left: -50px;
    margin-top: 50px;
    h1 {
      margin-left: 75px;
      margin-top: 250px;
      font-size: 3em;
    }
  }`
;

const About = (props) => {
  return ( 
    <div className={css.mainDiv}>
      <Container fluid style={{backgroundColor:"#f7f5f4 !important"}}>
          <Row>
            <Col className="p-0">
              <StyledJumbotron fluid>
                <Container fluid>
                </Container>
              </StyledJumbotron>
            </Col>
          </Row>
          <Container className="p-md-5 p-sm-0">
                  <h2 id="vision" className=" mt-2 mb-2">
                    Why VEF?
                  </h2>
                  <p className = {css.whyTxt} >
                    Our organization’s ability to create honest and transparent 
                    bonds directly with members in various communities of Nepal 
                    has ultimately strengthened our ability to gain the trust of 
                    professors, students, and other faculty in the schools we sponsor. 
                    Once we have such an understanding, then we as an organization are 
                    able to raise funds that directly go to our organization’s bank to 
                    ensure every student has an equal opportunity to succeed in their 
                    educational endeavors. Our team additionally implements a unique 
                    curriculum through on-ground training and discussions with school 
                    professors, and through this, our expenses are greatly cut down because 
                    our team works directly with faculty and professionals in Nepal via 
                    our 0n-ground team. Therefore, the funds our organization receives for 
                    students have a higher value and 100% of them go directly towards 
                    allocating the resources they need.
                    <br/>
                    <br/>
                    Our team in the USA conducts in-depth research we receive directly from 
                    our on-ground team in Nepal that processes surveys and collects data at 
                    each designated school. Our transparent collection of information and data 
                    allow our non-profit to thoroughly understand what a specific school and 
                    its students need the most.  Once we have such an understanding, then we 
                    as an organization are able to raise funds that directly go to our 
                    organization’s bank to ensure every student has an equal opportunity of 
                    success, implement our education curriculum through on-ground training and 
                    discussions with school professors, and allocate necessary educational 
                    resources to transform the education system in place at each school sponsored. 
                  </p>
          </Container>


          <Container className="p-md-5">
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center", alignItems: "center"}}>
              <img style={{height: "200px"}} src={education}/>
              <h2 id="vision" className=" mt-2 mb-2">
                Educational Sector
              </h2>
            </div>
            
                  <p className = {css.whyTxt} >
                  Our mission is to move towards high quality education standards in a safe, respectful, and inclusive environment that allots for an accumulation of life-long learning. In our desire to provide an inclusive environment that acknowledges and respects children from diverse backgrounds, our foundation has three overarching goals.
                  
                  <br></br> <br></br>
                  The first aims to provide access to resources including; proper learning materials, science lab equipment, and music/arts/sports equipment to foster growth in several different areas of child development and skills.
                  <br></br> <br></br>
                  The second goal is aimed at the training and effectiveness of teachers. Our foundation desires to see workshops for teachers that explain how to motivate students, and how to better understand their failures, emphasizing that students perceive and learn differently. The overall goal for teachers is for an increase in staff and as well as income. Making the teaching job not only a fulfilling experience but a desirable one as well.
                  <br></br> <br></br>
                  Our last goal is aimed at the students. Our desire is to provide scholarships that fund a scholar yearly, provide set lunches, organize a website of world scholarships and opportunities. As well as expand the opportunities that students have throughout educational opportunities and through our organization.
                  </p>
          </Container>

          <Container className="p-md-5 p-sm-0">
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center", alignItems: "center"}}>
              <img style={{height: "200px"}} src={health}/>
              <h2 id="vision" className=" mt-2 mb-2">
                Health Sector
              </h2>
            </div>
                  <p className = {css.whyTxt} >
                    The health system within Nepal suffers from insufficient human and financial resources, lack of comprehensiveness, limited institutional infrastructure/capacity, weak human resources and a need for management structure. Vision Education Foundation seeks the development of health systems well balanced in the human endowment and right to the greatest accessible standard of health. In order to stay consistent with the right to the greatest accessible standard of health, health systems should be effective, evidence based, and integration for the overall goal to preserve health and prevent disease.
                    <br></br> <br></br>
                    In order to achieve this highest level of health and meet the standards of effectiveness, the Vision Education Foundation desires to enable the citizens of Nepal to have effective Medical and Dental checkups. Which entails the necessities of a proper examination such as; height and weight checkup, BP, BS (providing children with daily vitamins). As well as education and kits to aid in the overall public health and hygiene of the people. These checkups, in conjunction with education on mental health and balanced diet education aid in the awareness of the people as whole. Leading to the ultimate goal of leading the people towards the overall premise of achieving their greatest standard of health.
                  </p>
          </Container>

          <Container className="p-md-5 p-sm-0">
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center", alignItems: "center"}}>
              <img style={{height: "200px"}} src={social}/>
              <h2 id="vision" className=" mt-2 mb-2">
                Social Sector
              </h2>
            </div>
            <p className = {css.whyTxt} >
              Due to the current educational standards in Nepal, the caste system, dowry system, and menstruation hygiene infringe upon the ability for effective education and social standards. One unacceptable standard is the lack of necessary materials for girls to attend school during their cycle, which directly causes loss of educational benefits and standards. In order to combat these deficiencies, the Vision Education Foundation desires to bring awareness that will constitute change among both the educational and social sector. However, our ability to bring awareness must start with a focus on constitutional and human rights. The inequality among men and women must be brought to the front burner. Bringing awareness to this will shed light on social norms that are unacceptable. Our mission through these awareness campaign is to put an end to domestic violence and sexual assault. One step at a time our foundation will aim to change the social and education norms that are outdated and unjust.
            </p>
          </Container>

          <Container className="p-md-5 p-sm-0">
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center", alignItems: "center"}}>
              <img style={{height: "200px"}} src={economic}/>
              <h2 id="vision" className=" mt-2 mb-2">
                Economic Sector
              </h2>
            </div>
            <p className = {css.whyTxt} >
              In order to fully understand the economic challenges facing the country, our organization is aiming to analyze and collect data from the communities. This will entail family income status, family status (single parent household or divorced, separated..) as well as dental and frequency of medical visits. We will also determine what one's main income source is and what occupation they partake in to make this income. Analyzing this data will provide our organization with a picture of where the country is currently, and what measures we should take to improve the economic sector. In order to fully determine the needs of the people and apply all of the other sectors effectively it is essential for the economic statistics of the people to be contracted.
            </p>
          </Container>

        </Container>
      </div>
  )
};

export { About };
