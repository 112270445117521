import React, {useState} from "react";
import { Button, Jumbotron, Container, Row, Col } from "react-bootstrap";
import css from "./bcchapter.module.css";


import styled from "styled-components";
import gasson from "assets/gasson.jpg";
import pdf from "assets/starter.pdf";

import donate from "assets/donate.png";
import starter from "assets/starter.png";
import apply from "assets/apply.png";

import donateHover from "assets/donate-hover.png";
import starterHover from "assets/starter-hover.png";
import applyHover from "assets/apply-hover.png";



const StyledJumbotron = styled.div`
  height: 70vh;
  width: auto;
  background-image: url(${gasson});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  h1 {
    margin-top: 125px;
    font-size: 6em;
    font-family: "Merriweather", serif;
  }

  /* Mobile */
  @media (max-width: 768px) {
    width: auto;
    height: 20vh;
    background-image: url(${gasson});
    margin-left: -50px;
    margin-top: 50px;
    h1 {
      margin-left: 75px;
      margin-top: 250px;
      font-size: 3em;
    }
  }`
;


function BCChapter() {
  var currentDonate = donate;

  const  hoveredDonate = () => {
    setDonateIndex(1);
  }

  const leftHoverDonate = () => {
    setDonateIndex(0);
  }

  const hoveredStarter = () => {
    setStarterIndex(1);
  }
  const leftHoverStarter = () => {
    setStarterIndex(0);
  }

  const hoverApply = () => {
    setApplyIndex(1);
  }

  const leftHoverApply = () => {
    setApplyIndex(0);
  }

  const [donateIndex, setDonateIndex] = useState(0);
  const donateArray = [donate, donateHover];

  const [starterIndex, setStarterIndex] = useState(0);
  const starterArray = [starter, starterHover];

  const [applyIndex, setApplyIndex] = useState(0);
  const applyArray = [apply, applyHover];

    
  

  return (
    <div style={{marginTop: "100px", marginBotton: "50px", backgroundColor: "#f7f4f2"}}>
        <Container fluid style={{backgroundColor:"#f7f5f4 !important"}}>
              <Row>
              <Col className="p-0">
                <StyledJumbotron fluid>
                  <Container fluid>
                  </Container>
                </StyledJumbotron>
              </Col>
              </Row>
        </Container>
        <Container className="p-md-5 p-sm-0">
              <h2 id="vision" className=" mt-2 mb-2" >
                <div style={{marginTop:"0px"}}>
                  Boston College Chapter
                </div>
                
              </h2>
              <p
                className={css.bcChapterWriting}
              >
                  The BC Chapter is an established club at Boston College that is composed of undergraduate students that 
                  are willing to contribute to the foundation's mission goals and make a change in the world. This group 
                  works closely with the Vision Education Foundation’s Boston Team and engages in a variety of activities 
                  to help spread our vision and make our goal more attainable. Our chapter will focus on advocacy, fundraising, 
                  and collaboration with other groups and organizations in order to raise awareness and gain the resources 
                  needed to impact the Nepali community. Through sheer passion and determination our BC Chapter will carry 
                  and manifest our foundation's goals.
                  
              </p>

              <Row style={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop: "50px"}}>
                <Col>
                  <a href="https://donorbox.org/vision-education-foundation-2" target = "_blank" onMouseEnter={(e) => hoveredDonate()} onMouseLeave={(e) => leftHoverDonate()}>
                    <img alt="don" style={{height: "200px"}} src={donateArray[donateIndex]}/>
                  </a>
                  
                  <div>
                    <p><b>Donate To Boston College Chapter</b></p>
                  </div>
                </Col>
                <Col>
                  <a href={pdf} target="_blank" onMouseEnter={(e) => hoveredStarter()} onMouseLeave={(e) => leftHoverStarter()}>
                      <img style={{height: "200px"}} src={starterArray[starterIndex]}/>
                  </a>
                  
                  
                  <div>
                    <p><b>VEF Chapter Starter Guide</b></p>
                  </div>
                </Col>
                <Col>
                  <a href="https://thevisioneducationfoundation.typeform.com/to/pwwTdn1X" target = "_blank" onMouseEnter={(e) => hoverApply()} onMouseLeave={(e) => leftHoverApply()}>
                    <img style={{height: "200px"}} src={applyArray[applyIndex]}/>
                  </a>
                  <div>
                    <p><b>Apply for a Chapter at your Institution</b></p>
                  </div>
                </Col>
              </Row>
      </Container>
    </div>
  );
}

export { BCChapter };
