import React, { useState } from "react";
import styled from "styled-components";
import { Button, Jumbotron, Container, Row, Col } from "react-bootstrap";

import firstprojectmain from "assets/firstprojectmain.jpg";
import library from "assets/library.jpg";
import sports from "assets/sports.JPG";
import hyg from "assets/hyg.jpg";
import css from "./first.module.css";


const StyledJumbotron = styled.div`
  height: 80vh;
  width: auto;
  margin-top: -90px;
  background-image: url(${firstprojectmain});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  h1 {
    margin-top: 125px;
    font-size: 6em;
    font-family: "Merriweather", serif;
  }

  /* Mobile */
  @media (max-width: 768px) {
    width: auto;
    height: 25vh;
    background-image: url(${firstprojectmain});
    margin-left: -50px;
    margin-top: 50px;
    h1 {
      margin-left: 75px;
      margin-top: 250px;
      font-size: 3em;
    }
  }
  `
;

const First = (props) => {
    return ( 
        <div style={{backgroundColor: "#f7f4f2", paddingBottom:"50px"}}>
          <Container fluid style={{backgroundColor:"#f7f5f4 !important"}}>
              <Row>
              <Col className="p-0">
                <StyledJumbotron fluid>
                  <Container fluid>
                  </Container>
                </StyledJumbotron>
              </Col>
              </Row>


            <Container className="p-md-5 p-sm-0" style={{marginBottom: "50px"}}>
                  <h2 className={css.visionH}>
                    First School Sponsorship: The Bigu Community
                  </h2>
                  <p
                    className={css.schoolSponsorshipProgramTxt}
                  >
                      After using our on-ground team to best assess the needs of students in this school, our organization was able to allocate the necessary funds and resources directly to this school in the rural area of Bigu, Nepal. All of these projects reflect and mold the vision of this foundation that takes into account what’s in the best of interest of the whole student. We ensure that every child in the rural communities of Nepal has the opportunity to overcome any socioeconomic barriers that have limited their educational opportunities by listening to and addressing their specific needs.
                  </p>
            </Container>

            <Container>
              <div className={css.projectLibraryContainer}>
                <h1 className={css.projectLibraryHR}>
                  Our Projects
                </h1>
                <hr
                  className={css.projectLibraryHRTwo}
                />

              </div>
              
            </Container>
            <Container style={{textAlign:"center", border:"1px solid #e7e4e2", boxShadow: "2.5px 2.5px #e7e4e2"}}>
              <Row style={{ backgroundColor: "white" }}>
                  <Col style={{padding: "0px"}}>
                    <div
                      className={css.libraryPic}
                      style={{
                        backgroundImage: `url(${library})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        margin: "0px"
                      }}
                    />
                  </Col>
                  <Col style={{padding: "0px"}}>
                    <Container style={{marginLeft: "-50px"}}>
                    
                      <p
                        className={css.projectLibraryTxt}
                      >
                        <div className={css.projectLibraryContainer}>
                          <h3 className={css.projectLibraryHR}>
                            [ Library Project ]
                          </h3>
                          <hr
                            className={css.projectLibraryHRTwo}
                          />

                        </div>
                      <p style={{fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px"}}>
                        Especially after the 2015 massive earthquake, the Bigu community continues to face the deep effects of a broken society subjected to poverty. Teachers and students have shared their struggles of having a school that is located in the Himalayas where resources are lacking. With $600, VEF was able to initiate various projects, including building a library, for students in the Bigu community of Nepal. The first school that VEF is sponsoring has 302 students. Our organization was able to provide 350 books and notebooks with the money donated directly to this school in Bigu. 
                      </p>
                      </p>

                      <br/>
                      </Container>
                    </Col>
                </Row>
            </Container>

            <Container style={{textAlign:"center", border:"1px solid #e7e4e2", boxShadow: "2.5px 2.5px #e7e4e2", marginTop: "50px"}}>
              <Row style={{ backgroundColor: "white" }}>
                  
                  <Col style={{padding: "0px"}}>
                    <Container style={{marginLeft: "0px"}}>
                    
                      <p
                        className={css.projectLibraryTxt}
                      >
                        <div className={css.projectLibraryContainer}>
                          <h3 className={css.projectLibraryHR}>
                            [ Sports Equipment Distribution ]
                          </h3>
                          <hr
                            className={css.projectLibraryHRTwo}
                          />

                        </div>
                      <p style={{fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px"}}>
                        After listening to what the students of the Bigu community needed, VEF launched this project to provide equipment necessary to play soccer, badminton, volleyball, and football. With these new opportunities, students will be able to remain active, enjoy a variety of sports, and most importantly get to be children together. 
                      </p>
                      </p>

                      <br/>
                      </Container>
                    </Col>
                    <div style={{display: "flex", alignItems: "right", justifyContent: "right", textAlign: "right"}}>
                      <Col style={{paddingRight: "0px"}}>
                        <div
                          className={css.picturesMobile}
                          style={{
                            height: "335px",
                            width: "467px",
                            backgroundImage: `url(${sports})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            margin: "0px"
                          }}
                        />
                      </Col>

                    </div>
                    
                </Row>
            </Container>

            <Container style={{textAlign:"center", border:"1px solid #e7e4e2", boxShadow: "2.5px 2.5px #e7e4e2", marginTop: "50px"}}>
              <Row style={{ backgroundColor: "white" }}>
                  <Col style={{padding: "0px"}}>
                    <div
                      className={css.picturesMobile}
                      style={{
                        height: "375px",
                        width: "523px",
                        backgroundImage: `url(${hyg})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        margin: "0px"
                      }}
                    />
                  </Col>
                  <Col style={{padding: "0px"}}>
                    <Container style={{marginLeft: "-20px"}}>
                    
                      <p
                        className={css.projectLibraryTxt}
                      >
                        <div className={css.projectLibraryContainer}>
                          <h3 className={css.projectLibraryHR}>
                            [ Hygiene Project ]
                          </h3>
                          <hr
                            className={css.projectLibraryHRTwo}
                          />

                        </div>
                      <p style={{fontFamily: "Arial, Helvetica, sans-serif", fontSize: "16px"}}>
                        In rural villages, like the Bigu community, there is always a concern of hygiene related communicable diseases especially since many rural communities lack access to hygiene-related items. 235 Hygiene-related kits were distributed to the students of this school in order to ensure that they have the necessary and proper resources for sanitation to decrease the spread of disease and prevent illnesses. The children were also given backpacks, tooth brushes, and toothpaste. Their educational dreams cannot become a reality without proper health and such is why our foundation created a vital short-term project to serve their most pressing needs. 
                      </p>
                      </p>

                      <br/>
                      </Container>
                    </Col>
                </Row>
            </Container>
        </Container>
    </div>
)};

export { First };
