import React from "react";

import { Button, Jumbotron, Container, Row, Col } from "react-bootstrap";


import gasson from "assets/gasson.jpg";
import styled from "styled-components";

import education from "assets/education.png";
import social from "assets/social.png";
import health from "assets/health.png";
import nepalMap from "assets/nepal-map.jpg";

import css from "./work.module.css";

const StyledJumbotron = styled.div`
  height: 70vh;
  width: auto;
  background-image: url(${gasson});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  h1 {
    margin-top: 125px;
    font-size: 6em;
    font-family: "Merriweather", serif;
  }

  /* Mobile */
  @media (max-width: 768px) {
    width: auto;
    height: 20vh;
    background-image: url(${gasson});
    margin-left: -50px;
    margin-top: 50px;
    h1 {
      margin-left: 75px;
      margin-top: 250px;
      font-size: 3em;
    }
  }`
;


function Work() {
  

  return (
    <div className={css.mainWorkContainer}>
        <Container fluid>
          <Row>
            <Col className="p-0">
                {/* <StyledJumbotron fluid>
                  <Container fluid>
                  </Container>
                </StwqqwyledJumbotron> */}
            <Container className={css.centerTop} style={{minWidth:"100%"}}>
            {/* <p className={css.ourWorkContainer}>Our Work</p> */}
            <Row style={{ backgroundColor: "#f7f4f2", marginTop: "0px" }}>
              <Col style={{padding:"0px"}}>
              <div className = {css.workContainer}>
                <div className = {css.ourWorkTxt}>
                  <h2 id="Work" style={{fontFamily: "Roboto", fontWeight:"bold", fontSize:"45px", color:"#369FF4"}}>
                    Our Work
                  </h2>
                </div>
                <hr className = {css.ourWorkHR}/>
                {/* <div className={css.ourWorkHeader}>
                  <h2 className={css.ourWorkHeader}>
                    School Sponsorship Program
                  </h2>
                </div> */}
                <p className = {css.ourWorkParagraph2}>
                Our overall work is founded on tailoring to each school program based on its geographic location and overall financial status. Our school sponsorship program focuses on enhancing students' educational areas of interest/needs, whether it be resources for literacy, math, STEM, agriculture/vocational training, as well as a curriculum we have created to work with professors in Nepal. <br></br> <br></br>
                One constant variable across all of the schools we sponsor is a <strong>“Girls Empowerment Through Education Program” </strong>that targets girls who are statistically proven to drop out of school as 80% do by the time they reach higher secondary schooling (11th grade). Given that the majority of girls are not able to graduate from higher secondary schooling let alone continue their educational endeavors via attending colleges our organization seeks to re-write this statistic to a percentage much smaller. This immensely high percentage is exactly why our team seeks to implement this program in every school with the willful hope to decrease this number overtime. </p> 
                <hr className = {css.ourWorkHR}/>
              </div>
              </Col>
            </Row>
            <Row style={{minWidth:"100%"}}>
              <Col className="col-md-4 col-md-offset-2">
                <div>
                    <img alt="don" style={{height: "170px"}} src={education}/>
                </div>
                <div>
                    <p><b>###</b></p>
                </div>
                <div>
                    <p><b>Water Projects Funded</b></p>
                </div>
              </Col>
              <Col className="col-md-4">
                <div>
                    <img alt="don" style={{height: "170px"}} src={social}/>
                </div>
                <div>
                    <p><b>###</b></p>
                </div>
                <div>
                    <p><b>People Will Be Served</b></p>
                </div>
              </Col>
              <Col className="col-md-4">
                <div>
                    <img alt="don" style={{height: "170px"}} src={health}/>
                </div>
                <div>
                    <p><b>###</b></p>
                </div>
                <div>
                    <p><b>Countries</b></p>
                </div>
              </Col>
            </Row>
            
            <Row>  
              <div>
                <img alt="map" style={{height: "270px"},{width: "570px"}} src={nepalMap}/>

                <p><b>MAP PHOTO HERE</b></p>
              </div>
            </Row>

            <Container className="p-md-5">
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center", alignItems: "center"}}>
              <img style={{height: "200px"}} src={education}/>
              <h2 id="vision" className="mt-2 mb-2">
            How We Work
              </h2>
            </div>
            
                  <p className = {css.whyTxt} >
                      Our team genuinely listens to the needs of the students we serve in Nepal in 
                      order to most efficiently strengthen the quality of education at the schools we sponsor. Our surveys and research conducted by our on-ground team continues to accumulate data
                      we use to most thoroughly address these diverse demographics in Nepal. 
                      <br></br>
                      <br></br>
                       Our organization recognizes the unique demographics in Nepal that directly affect access to education and its overall quality. The sociocultural and economic diversity of Nepal requires nuanced and attentive research and work. Our organization highly values conducting in-depth research in these varying demographics. Our ongoing research in various geographical locations in Nepal has so far statistically vocalized the divisions in the quality of education between various ethnic, religious, and cultural groups which is why we approach each school in Nepal accordingly based on its specific needs.

                  </p>
          </Container>

          <Container className="p-md-5">
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center", alignItems: "center"}}>
              <img style={{height: "200px"}} src={education}/>
              <h2 id="vision" className="mt-2 mb-2">
                Measuring Our Impact
              </h2>
            </div>
            
                  <p className = {css.whyTxt} >
                  Our work will be traced through measuring the following data which entails both before and after statistics listed below:
                      
                      <li>Graduation rates </li>
                    
                    <li>Literacy rates</li>
                    <li>Math rates</li>
                    <li>STEM rates</li>
                    <li>Agriculture/Vocational rates</li>
                    

                    We, as an organization, acknowledge how challenging it is for graduate students to be hired in their areas of expertise in Nepal, which is why we ultimately seek to engage with and sustain the overall community and work market of Nepal. Through our team’s connections with professionals we have the ability to form bridges between our students and corresponding professionals who can eventually hire them, assist them, and serve the particular student’s educational endeavors to live a safer, healthier, educated, and more efficient life. 

                  </p>
          </Container>

            
              {/* <Container className="p-md-5 p-sm-0" style={{marginBottom: "50px"}}>
                    <h2 id="vision" className=" mt-2 mb-2">
                      Stories From the Field
                    </h2>
                    <p 
                      className = {css.storiesFromFieldTxt} 
                    >
                    <p>
                        Stories from field para
                    </p>
                    </p>
              </Container> */}
            </Container>

            </Col>
          </Row>
      </Container>
    </div>
  );
}

export { Work };
