import React, { useState, useEffect } from "react";
import { Button, Image, Navbar, Nav, NavDropdown, Dropdown} from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';
import { Link, useHistory } from "react-router-dom";
import visionLogo from "assets/visionLogo.png";
import css from "./navbar.module.css"


function NavBar() {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  let history = useHistory();

  const showDropdown = (e)=>{
    setShow(!show);
  }
  const hideDropdown = (e) => {
      setShow(false);
  }
  const clickedHeader = (e) => {
    console.log("HIT");
    // history.push("/work");
    
  }

  const teamshowDropdown = (e) => {
    setShow2(!show2)
  }

  const teamhideDropdown = (e) => {
    setShow2(false);
  }

  const joinShowDropdown = (e) => {
    setShow3(!show3);
  }
  const joinHideDropdown = (e) => {
    setShow3(false);
  }

  



  return (
    <Navbar
      collapseOnSelect
      expand="sm"
      fixed="top"
      style={{ backgroundColor: "white"}}
    >
      {console.log(show)}
      <Navbar.Brand href="/">
        <Image className={css.vefImg} src={visionLogo} />
        <Navbar.Text className={css.vefText}> Vision Education Foundation</Navbar.Text>
      </Navbar.Brand>
      
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto"></Nav>
        <Nav>
          <Nav.Link href="#/home">HOME</Nav.Link>
          {/* <Nav.Link href="#/stories">THEIR STORIES</Nav.Link> */}
          <NavDropdown
            title = "OUR WORK"
            onMouseEnter = {(e) => showDropdown()}
            onMouseLeave = {(e) => hideDropdown()}
            onClick = {(e) => clickedHeader()}
            show={ show }
            className={css.navBarDropdown}
            id = "our-work-tab"
          >
            <Nav.Link style={{marginTop: "-11px", paddingTop: "20px"}} href="#/firstproject">
                PAST PROJECTS
            </Nav.Link>

            <Nav.Link href="#/projects">COVID-19 RELIEF</Nav.Link>

            
          </NavDropdown>
          

          {/* <Nav.Link href="#/about">WHY VEF</Nav.Link> */}
          {/* <Nav.Link href="#/team">Team</Nav.Link> */}
          <NavDropdown
            title = "ABOUT US"
            onMouseEnter = {(e) => teamshowDropdown()}
            onMouseLeave = {(e) => teamhideDropdown()}
            show={ show2 }
            className={css.navBarDropdown}
            id = "our-team-tab"
          >
            <Nav.Link style={{marginTop: "-11px", paddingTop: "20px"}} href="#/about">
                WHY VEF
            </Nav.Link>
            <Nav.Link style={{marginTop: "-11px", paddingTop: "20px"}} href="#/bostonteam">
                BOSTON TEAM
            </Nav.Link>
            <Nav.Link style={{marginTop: "0px"}} href="#/nepalteam">
                NEPAL TEAM
            </Nav.Link>
            <Nav.Link style={{marginTop: "0px"}} href="#/bcchapter">
                BC CHAPTER
            </Nav.Link>
            
          </NavDropdown>

          {/* <Nav.Link href="#/bcchapter"> BC CHAPTER </Nav.Link> */}
          {/* <Nav.Link target="blank" href="https://thevisioneducationfoundation.typeform.com/to/b0bdfhsC">JOIN VEF</Nav.Link> */}
          <NavDropdown
            title = "JOIN VEF"
            onMouseEnter = {(e) => joinShowDropdown()}
            onMouseLeave = {(e) => joinHideDropdown()}
            show={ show3 }
            className={css.navBarDropdown}
            id = "our-team-tab"
          >
            <Nav.Link style={{marginTop: "-11px"}} href="#/get-involved">
                VOLUNTEER
            </Nav.Link>

            <Nav.Link style={{marginTop: "-11px", paddingTop: "20px"}} target="_blank" href="https://thevisioneducationfoundation.typeform.com/to/b0bdfhsC">
                APPLY NOW
            </Nav.Link>

            <Nav.Link style={{marginTop: "-11px", paddingTop: "20px"}} target="_blank" href="https://thevisioneducationfoundation.typeform.com/to/pwwTdn1X">
                OPEN CHAPTER
            </Nav.Link>

          </NavDropdown>
          <Button
            className="ml-3 donateButton"
            variant="primary"
            sz="lg"
            target="_blank"
            href="https://donorbox.org/vision-education-foundation"
            
          >
            <b>DONATE</b>
            
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export { NavBar };
