import React, { useState } from "react";
import { Button, Jumbotron, Container, Row, Col } from "react-bootstrap";
import ReactPlayer from "react-player";
import styled from "styled-components";
import Image from 'react-bootstrap/Image';

import { TeamContainer } from "components";


// import volleyballImage from "assets/";
import team from "assets/team.png";
import nepalTeam from "assets/nepal-team.JPG";

import data from './team_info.json'

import css from "./team.module.css";


const StyledJumbotron = styled.div`
  height: 80vh;
  width: auto;
  background-image: url(${team});
  margin-top: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  h1 {
    margin-top: 125px;
    font-size: 6em;
    font-family: "Merriweather", serif;
  }

  /* Mobile */
  @media (max-width: 768px) {
    width: auto;
    height: 20vh;
    background-image: url(${team});
    margin-left: 0px;
    margin-top: 60px;
    h1 {
      margin-left: 100px;
      margin-top: 250px;
      font-size: 3em;
    }
  }`
;

const Team = (props) => {
  let boston_team = data["boston-team"];
  console.log(boston_team);

  


  return (
    <Container fluid className={css.mainContainer}>
      <Row>
        <Col className="p-0">
            <StyledJumbotron fluid>
              <Container fluid>
              </Container>
            </StyledJumbotron>
        </Col>
      </Row>
      <Container className="p-md-5 p-sm-0">
              <h2 id="vision" className=" mt-2 mb-2" >
                <div style={{marginTop:"0px"}}>
                  Boston Team
                </div>
                
              </h2>
              <p
                className={css.mainParagraph}
              >
                Our team is made up of well-educated, passionate,  and determined undergraduate students and alumni from the Boston College community. The diverse backgrounds and experiences from each team member helps contribute to the foundation's goal. Working as a unified team, we are the Boston team that will manifest our goals.
              </p>
      </Container>

      {/* Boston Team Members */}
      {boston_team.map(user => (<TeamContainer user = {user} />))}
        
        

  </Container>
  );
};

export { Team };
