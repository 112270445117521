import React, { useState } from "react";
import styled from "styled-components";
import { Button, Jumbotron, Container, Row, Col } from "react-bootstrap";

import covidimg from "assets/covid_background.JPG";
import covid1 from "assets/covid1.jpg";
import covid2 from "assets/covid2.jpg";
import covid3 from "assets/covid3.JPG";
import econimg from "assets/econimg.JPG";

import css from "./projects.module.css";

const StyledJumbotron = styled.div`
  height: 80vh;
  width: auto;
  background-image: url(${covidimg});
  background-size: cover;
  margin-top:90px;
  background-position: center;
  background-repeat: no-repeat;

  h1 {
    margin-top: 125px;
    font-size: 6em;
    font-family: "Merriweather", serif;
  }

  /* Mobile */
  @media (max-width: 768px) {
    width: auto;
    height: 20vh;
    background-image: url(${covidimg});
    margin-left: -50px;
    margin-top: 60px;
    h1 {
      margin-left: 75px;
      margin-top: 250px;
      font-size: 3em;
    }
  }`
;


const Projects = (props) => {
  return ( 
    <div className={css.mainDiv}>
      
      <Container fluid style={{backgroundColor:"#f7f5f4"}}>
          <Row>
            <Col className="p-0">
              <StyledJumbotron fluid>
                <Container fluid>
                </Container>
              </StyledJumbotron>
            </Col>
          </Row>
          
          <Container className="p-md-5 p-sm-0" style={{marginBottom: "50px"}}>
                  <h2 id="vision" className=" mt-2 mb-2">
                    About the Project
                  </h2>
                  <p 
                    className = {css.aboutProjectsTxt} 
                  >
                  <p>
                      The Vision Education Foundation organization is dedicated 
                      to ensure that every child has the opportunity to overcome 
                      any socio-economic barriers that have limited their educational 
                      dreams and opportunities. COVID-19 has affected nearly every 
                      country in the world and especially in Nepal. Despite COVID-19 
                      having  limited our work, it did not stop us but rather has 
                      driven us to work harder to effectuate the positive change we 
                      envision. The children that we work with have been completely 
                      removed from their access to any form of education due to the 
                      lockdown Nepal has enforced. Due to the fact that these children 
                      do not have resources to study online such as accessible laptops 
                      and or internet connection, they are inevitably set-back in terms 
                      of their educational progress, future endeavors, and overall 
                      well-being. Not only has their access to education halted, 
                      but their health has also been incredibly jeopardized due to 
                      increasing food insecurities and lack of safety. 
                  </p>
                  </p>
            </Container>

            <Container style={{textAlign:"center", border:"1px solid #e7e4e2", boxShadow: "2.5px 2.5px #e7e4e2", marginTop: "50px"}}>
              <Row style={{ backgroundColor: "white" }}>
                  <Col className={css.picturesMobile} style={{padding: "0px"}}>
                    <div
                      
                      style={{
                        height: "385px",
                        width: "523px",
                        backgroundImage: `url(${covid1})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        margin: "0px"
                      }}
                    />
                  </Col>
                  <Col style={{padding: "0px"}}>
                    <Container className={css.marginOfTop} style={{marginTop: '20px'}}>
                    
                      <p
                        className={css.projectLibraryTxt}
                      >
                      <div style={{textAlign: "center"}}>
                        <h3>
                          [ COVID-19 Project ]
                        </h3>
                        <hr
                          className={css.projectLibraryHRTwo}
                        />

                      </div>
                      <p className={css.aboutCovid} style={{fontFamily: "Arial, Helvetica, sans-serif"}}>
                        Vision Education Foundation recognizes the life-threatening 
                        consequences COVID-19 has caused for the children we work with, 
                        their families, and their communities. The average daily wage of 
                        the people in  (Bigu, Kapan, Kathmandu)  during the pandemic is 
                        $7 without any additional government support. The fatally high 
                        rates of malnutrition along with weakly implemented health systems 
                        has created a dangerous combination that directly affects a child’s 
                        education and health. Our organization serves children’s educational 
                        needs so that they can live a safer, healthier, and brighter future. 
                        Therefore, it is our duty to ensure that these children can first 
                        and foremost be healthy and safe during these unprecedented times. 
                      </p>
                      </p>

                      <br/>
                      </Container>
                    </Col>
                </Row>
          </Container>

          <Container style={{textAlign:"center", border:"1px solid #e7e4e2", boxShadow: "2.5px 2.5px #e7e4e2", marginTop: "50px"}}>
              <Row style={{ backgroundColor: "white" }}>
                  
                  <Col style={{padding: "0px"}}>
                    <Container className={css.marginOfBottom} style={{marginTop: '50px'}}>
                    
                      <p
                        className={css.projectLibraryTxt}
                      >
                      
                      <p style={{fontFamily: "Arial, Helvetica, sans-serif"}}>
                        Through listening to the pressing needs of the children and their families in Nepal,
                        our team has created The Direct COVID-19 Relief Project. This project focused directly
                        on responding to and serving the vulnerable youth in (Bigu/Kapan and kathmandu). We, as
                        an organization, are dedicated to prioritizing the health and overall well-being of these
                        children during this pandemic. Our team has been able to persevere in serving these children
                        despite international lockdowns and travel restrictions in Nepal. We were proudly able to
                        distribute 25 pounds of rice, 5 pounds of lentils, 1 liter of oil, 1 mask, and 2 packs of
                        soap to 60 people. 75000Rs was used for funding the project and we are expecting this relief
                        to last about a month with the family number of three.
                      </p>
                      </p>

                      <br/>
                      </Container>
                    </Col>

                    <Col className={css.picturesMobile} style={{padding: "0px", textAlign: "right", justifyContent: "right", alignItems: "right", margin: "0px"}}>
                    <div
                    
                      style={{
                        height: "410px",
                        width: "503px",
                        backgroundImage: `url(${covid2})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        marginLeft: "70px"
                      }}
                    />
                  </Col>
                </Row>
          </Container>
        </Container>
      </div>
  )
};

export { Projects };
